/* Estilos principais para o formulário */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  background-color: #f7f9fb;
  background-image: radial-gradient(circle at top left, rgba(0, 128, 0, 0.2), transparent), radial-gradient(circle at bottom right, rgba(0, 64, 0, 0.2), transparent);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

/* Logo */
.logo {
  width: 120px;
  height: auto;
  margin-bottom: 20px;
}

/* Estilo para o título */
.form-title {
  font-size: 2em;
  margin-bottom: 15px;
  color: #003366;
}

/* Mensagem de sucesso */
.success-message {
  background-color: #e0f7e9;
  color: #006633;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  font-size: 1em;
  box-sizing: border-box;
}

/* Estilos do formulário */
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Estilo para os rótulos (label) */
.form-label {
  margin-bottom: 15px;
  font-size: 1em;
  color: #333;
  text-align: left;
}

/* Estilo para os campos de input */
.form-input {
  width: 100%;
  padding: 12px;
  font-size: 1em;
  border: 1px solid #bbb;
  border-radius: 6px;
  margin-top: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.form-input:focus {
  border-color: #006633;
  outline: none;
}

/* Estilo para o botão de enviar */
.form-button {
  width: 100%;
  padding: 14px;
  background-color: #006633;
  color: white;
  font-size: 1.2em;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.form-button:hover {
  background-color: #004d26;
}

/* Responsividade para telas menores */
@media (max-width: 600px) {
  .form-container {
    padding: 15px;
  }

  .form-title {
    font-size: 1.8em;
  }

  .form {
    padding: 20px;
  }

  .form-input, .form-button {
    font-size: 1em;
  }
}
